import React from 'react';
import { navigate } from "gatsby";
import { Helmet } from 'react-helmet';
import { useQueryParam, StringParam } from "use-query-params";

const IndexPage: React.FC = () => {
  const [p] = useQueryParam('p', StringParam);

  const platformCheck = function (userAgent: string) {
    if (/like Mac OS X/.test(userAgent)) return 'ios'
    if (/Android/.test(userAgent)) return 'android'
    return 'web'
  }

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    let redirect = 'https://highso.app'

    const platform = p || platformCheck(userAgent)

    if (platform === 'ios') {
      redirect = 'https://itunes.apple.com/app/id1488302466'
    } else if (platform === 'android') {
      redirect = 'https://play.google.com/store/apps/details?id=io.nuca.highso'
    }

    navigate(redirect)
  }, []);

  return (
    <div>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-127746049-3"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-127746049-3');
          `}
        </script>
      </Helmet>
    </div>
  )
}

export default IndexPage